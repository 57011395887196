import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ConfirmHelper from '../../helpers/ConfirmHelper';
import { fieldRequired } from '../../helpers/fieldRequired';
import { getCurrentUser } from '../../helpers/GetCurrentUser';
import { removeLoginDetails } from '../../helpers/Lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { showToast } from '../../helpers/ToastHelper';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

export default class Users extends Component {

  constructor(props) {
    super(props);
    this.state = {
      orderBy: "FirstNameDown",
      userModal: false,
      users: [],
      userTypes: [{
        id: 10,
        name: "User",
      }, {
        id: 100,
        name: "Admin",
      }],
      user: {
        AddedBy: getCurrentUser().id,
        userType: 10
      },
      userTypeDropDownOpen: false,
    }
    this.userModalToggle = this.userModalToggle.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.toggleUserTypeDropDown = this.toggleUserTypeDropDown.bind(this);
    this.saveUser = this.saveUser.bind(this);
  }


  componentDidMount() {
    this.loadUsers();
  }

  userModalToggle = () => {
    this.setState({ userModal: !this.state.userModal })
  }

  updateUser = () => {
    let valid = true;
    valid &= fieldRequired(this.state.user.firstName, 'first-name-error', "* required");
    valid &= fieldRequired(this.state.user.lastName, 'last-name-error', "* required");
    valid &= fieldRequired(this.state.user.password, 'password-error', "* required");
    valid &= fieldRequired(this.state.user.emailAddress, 'email-address-error', "* required");
    if (valid) {
      this.userModalToggle();
      this.saveUser();
    }
  }

  toggleUserTypeDropDown = () => {
    this.setState({ userTypeDropDownOpen: !this.state.userTypeDropDownOpen })
  }

  sortBy(header) {
    if (this.state.orderBy.includes(header)) {
      if (this.state.orderBy.includes("Down")) {
        this.setState({ orderBy: header + "Up" })
      } else {
        this.setState({ orderBy: header + "Down" })
      }
    } else {
      this.setState({ orderBy: header + "Down" })
    }
  }

  renderSortArrow(header) {
    return this.state.orderBy === header + "Down" ? <i className='fa fa-long-arrow-down'></i> : this.state.orderBy === header + "Up" ? <i className='fa fa-long-arrow-up'></i> : <></>
  }

  render() {
    return (
      <div>
        <div className='d-flex justify-content-between align-items-center'>
          <div>
            <h1>Users</h1>
          </div>
          <div>
            <Button color="primary" onClick={() => {
              this.setState({ user: {} })
              this.userModalToggle()
            }}>Add User <i className='fa fa-plus-circle ms-2'></i></Button>
          </div>
        </div>
        <div>
          <table className="table table-striped table-responsive ">
            <thead className='bg-blue'>
              <tr>
                <td style={{ cursor: "pointer" }} onClick={() => this.sortBy("FirstName")}>{this.renderSortArrow("FirstName")} First Name</td>
                <td style={{ cursor: "pointer" }} onClick={() => this.sortBy("LastName")}>{this.renderSortArrow("LastName")} Last Name</td>
                <td style={{ cursor: "pointer" }} onClick={() => this.sortBy("Email")}>{this.renderSortArrow("Email")} Email Address</td>
                <td style={{ cursor: "pointer" }} onClick={() => this.sortBy("Phone")}>{this.renderSortArrow("Phone")} Phone Numbers</td>
                <td style={{ cursor: "pointer" }} onClick={() => this.sortBy("Type")}>{this.renderSortArrow("Type")} User Type</td>
                <td className='text-end'>Action</td>
              </tr>
            </thead>
            <tbody>
              {/*{console.log(this.state.users)}*/}
              {this.state.users.length ? this.state.users.sort((a, b) => {
                switch (this.state.orderBy) {
                  case "FirstNameDown":
                    return a.firstName.localeCompare(b.firstName);
                  case "FirstNameUp":
                    return b.firstName.localeCompare(a.firstName);
                  case "LastNameDown":
                    return a.lastName.localeCompare(b.lastName);
                  case "LastNameUp":
                    return b.lastName.localeCompare(a.lastName);
                  case "EmailDown":
                    return a.emailAddress.localeCompare(b.emailAddress);
                  case "EmailUp":
                    return b.emailAddress.localeCompare(a.emailAddress);
                  case "PhoneDown":
                    return a.mobile.localeCompare(b.mobile);
                  case "PhoneUp":
                    return b.mobile.localeCompare(a.mobile);
                  case "TypeDown":
                    return a.userType - b.userType;
                  case "TypeUp":
                    return b.userType - a.userType
                  default:
                    return a.firstName.localeCompare(b.firstName);
                }
              }).map((user, index) => {
                return (
                  <tr key={index}>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{user.emailAddress}</td>
                    <td>{user.mobile}</td>
                    <td>{this.state.userTypes.find(item => item.id === user.userType)?.name}</td>
                    <td >
                      <div className='d-flex justify-content-end'>
                        <div onClick={() => {
                          this.setState({ user })
                          this.userModalToggle();
                        }}><i className='fa fa-edit mx-2' title="Edit user"></i></div>
                        <div onClick={() => this.deleteUser(user.id)}><i className='fa fa-trash-alt text-danger' title="Delete user"></i></div>
                      </div>
                    </td>
                  </tr>
                )
              }) : <tr><td colSpan='6' className='text-center'>No User Found</td></tr>}
            </tbody>
          </table>
        </div>
        <Modal isOpen={this.state.userModal} className="modal-lg">
          <ModalHeader>Edit User</ModalHeader>
          <ModalBody>
            <div className='row'>
              <div className="mb-3 col">
                <label htmlFor="firstName" className="form-label">
                  First Name
                  <div className='error' id='first-name-error'></div>
                </label>
                <input type="text" className="form-control" id="firstName" name="firstName" value={this.state.user.firstName} onChange={(e) => this.setState({ user: { ...this.state.user, firstName: e.target.value } })} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="lastName" className="form-label">
                  Last Name
                  <div className='error' id='last-name-error'></div>
                </label>
                <input type="text" className="form-control" id="lastName" name="lastName" value={this.state.user.lastName} onChange={(e) => this.setState({ user: { ...this.state.user, lastName: e.target.value } })} />
              </div>
            </div>
            <div className='row'>
              <div className="mb-3 col">
                <label htmlFor="Email Address" className="form-label">
                  Email Address
                  <div className='error' id='email-address-error'></div>
                </label>
                <input type="text" className="form-control" id="Email" name="Email" value={this.state.user.emailAddress} onChange={(e) => this.setState({ user: { ...this.state.user, emailAddress: e.target.value } })} />
              </div>
              <div className="mb-3 col">
                <label className="form-label">
                  Phone Number
                  <div className='error' id='phone-number-error'></div>
                </label>
                <input type="text" className="form-control" id="mobile" name="mobile" value={this.state.user.mobile} onChange={(e) => this.setState({ user: { ...this.state.user, mobile: e.target.value } })} />
              </div>
            </div>
            <div className='row'>
              <div className="mb-3 col">
                <label className="form-label">User Type</label>
                <DropDownListComponent id="ddUserType" dataSource={this.state.userTypes} fields={{ text: 'name', value: 'id' }} placeholder='Select User Type' floatLabelType='Never' showClearButton={true} value={this.state.user.userType} onChange={e => this.setState({ user: { ...this.state.user, userType: e.value } })} /><div id='tbPotentialRiskUsedError' className='error-message' />
                {/*<Dropdown isOpen={this.state.userTypeDropDownOpen} toggle={this.toggleUserTypeDropDown}  >*/}
                {/*  <DropdownToggle nav caret className='w-100 text-light'>*/}
                {/*    <input type="text" className="form-control" id="UserType" name="UserType" value={this.state.userTypes.find(item => item.id === this.state.user.userType)?.name} onChange={(e) => this.setState({ user: { ...this.state.user, userType: this.state.userTypes.find(item => item.name === e.target.value)?.id } })} />*/}
                {/*  </DropdownToggle>*/}
                {/*  <DropdownMenu className='bg-light'>*/}
                {/*    {this.state.userTypes.map((item, index) => {*/}
                {/*      return (*/}
                {/*        <DropdownItem key={index} onClick={() => this.setState({ user: { ...this.state.user, userType: item.id } })}>*/}
                {/*          {item.name}*/}
                {/*        </DropdownItem>*/}
                {/*      )*/}
                {/*    })}*/}
                {/*  </DropdownMenu>*/}
                {/*</Dropdown>*/}
              </div>
              <div className="mb-3 col">
                <label htmlFor="password" className="form-label">
                  Password
                  <div className='error' id='password-error'></div>
                </label>
                <input type="password" className="form-control" id="password" name="password" value={this.state.user.password} onChange={(e) => this.setState({ user: { ...this.state.user, password: e.target.value } })} />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.userModalToggle}>Cancel<i className="far fa-times-circle ms-2"></i></Button>
            <Button color="primary" onClick={this.updateUser}>Save<i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
        <ThrobbleHelper />
      </div>
    )
  }

  loadUsers = async () => {
    try {
      ThrobbleHelper.ToggleThrobble(true, "Loading Users");
      const responses = await fetch('api/userdata', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      })
      if (responses.ok) {
        const data = await responses.json();
        this.setState({ users: data });
        ThrobbleHelper.ToggleThrobble(false, true);
      } else {
        if (responses.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else
          ThrobbleHelper.ToggleThrobble(false, true);
      }

    } catch (error) {
      console.error(error);
      ThrobbleHelper.ToggleThrobble(false, true);
    }
  }

  saveUser = async () => {
    try {
      ThrobbleHelper.ToggleThrobble(true, "Saving User");
      const response = await fetch('api/userdata', {
        method: this.state.user.id ? "PUT" : 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(this.state.user)

      })
      if (response.ok) {
        this.loadUsers();
        showToast("success", "Save User", "User was successfully saved");
      } else {
        if (response.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else {
          ThrobbleHelper.ToggleThrobble(false, true);
          showToast("error", "Save User", "There was an error saving user.")
        }
      }

    } catch (error) {
      console.error(error)
      ThrobbleHelper.ToggleThrobble(false, true);
      showToast("error", "Save User", "There was an error saving user.")
    }
  }

  deleteUser = (id) => {
    const ConfirmProps = { DeleteId: id, Title: "Delete User", Message: "Are you sure you want to delete this user?" };
    confirmAlert({
      customUI: ({ onClose }) => <ConfirmHelper {...ConfirmProps} onClose={onClose} onConfirm={this.deleteUserData} />
    })
  }

  deleteUserData = async (id) => {
    try {
      ThrobbleHelper.ToggleThrobble(true, "Deleting user");
      const response = await fetch('api/userdata/' + id, {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + localStorage.getItem('token')
        },
      })
      if (response.ok) {
        this.loadUsers();
        showToast("success", "Delete User", "User was successfully deleted");
      } else {
        if (response.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else {
          ThrobbleHelper.ToggleThrobble(false, true);
          showToast("error", "Delete User", "There was an error deleting user.")
        }
      }

    } catch (error) {
      ThrobbleHelper.ToggleThrobble(false, true);
      showToast("error", "Delete User", "There was an error deleting user.")
      console.error(error)
    }
  }
}