/* eslint-disable array-callback-return */
import parse from 'html-react-parser';
import moment from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { Card, CardBody, Input } from 'reactstrap';
import { myContext } from '../Context';
import { checkColorBrightness } from '../helpers/global';
import ThrobbleHelper from '../helpers/ThrobbleHelper';

class CaseLoad extends Component {

  static displayName = CaseLoad.name;
  constructor(props) {
    super(props);
    this.state = {
      active: "myOpenCases",
      cases: [],
      statuses: [],
      ddStatuses: [],
      filterCase: 2,
      search: "",
      loading: false,
      orderBy: "AssignedDateDown",
      level: 0,
      levels: [],
      ddLevels: [],
      outcomes: [],
      ddOutcomes: [],
      users: [],
      tasks: [],
      AssignFilter: 0,
      LevelFilter: 0,
      OutcomeFilter: 0,
      showFor: 3,
      oneDayInMilliseconds: 24 * 60 * 60 * 1000,
      specificDate: moment().format("yyyy-MM-DD"),
      filterDateRange: [new Date(moment().add(-(30 * 6), "days").format("yyyy-MM-DD")), new Date(moment().format("yyyy-MM-DD"))]
    }

    this.activeButton = this.activeButton.bind(this);
    this.renderDataTable = this.renderDataTable.bind(this);
    this.renderSelectedTable = this.renderSelectedTable.bind(this);
    this.toggleMediaDropDown = this.toggleMediaDropDown.bind(this);
    // this.renderNewCasesTable = this.renderNewCasesTable.bind(this);
    this.openMultiSelect = this.openMultiSelect.bind(this);
    this.toggleTaskDropDown = this.toggleTaskDropDown.bind(this);
    this.toggleTypeOfCases = this.toggleTypeOfCases.bind(this);
    this.toggleCaseModal = this.toggleCaseModal.bind(this);
    // this.saveCase = this.saveCase.bind(this);
    this.editCases = this.editCases.bind(this);
    this.sortBy = this.sortBy.bind(this);
    this.renderSortArrow = this.renderSortArrow.bind(this);
    CaseLoad.parentObj = this;
  }

  componentDidMount() {
    async function load() {
      await CaseLoad.parentObj.loadCases();
      let id = CaseLoad.parentObj.context.caseId;
      if (id) {
        CaseLoad.parentObj.editCases(id);
      }
    }
    load()
  }

  activeButton(button) {
    if (button === this.state.active) {
      return "btn-active"
    } else {
      return "btn-inactive"
    }
  }

  handleClickOutside = (event) => {
    let Id = event.target.id;
    if (event.target.tagName !== "BUTTON" && event.target.tagName !== "INPUT" && event.target.tagName !== "TD" && !this.state.allMedia.includes(event.target.textContent) && event.target.textContent !== "- Select -" && event.target.textContent !== this.state.media.length + " Selected" && !Id.includes("checkbox")) {
      this.setState({ mediaDropdown: false })
    }
  };

  openMultiSelect() {
    this.setState({ mediaDropdown: true })
  }

  toggleTypeOfCases() {
    this.setState({ casesOpen: !this.state.casesOpen })
  }

  editCases(id) {
    window.location.href = 'case-file/'
  }

  sortBy(header) {
    if (this.state.orderBy.includes(header)) {
      if (this.state.orderBy.includes("Down")) {
        this.setState({ orderBy: header + "Up" })
      } else {
        this.setState({ orderBy: header + "Down" })
      }
    } else {
      this.setState({ orderBy: header + "Down" })
    }
  }

  renderSortArrow(header) {
    return this.state.orderBy === header + "Down" ? <i className='fa fa-long-arrow-down'></i> : this.state.orderBy === header + "Up" ? <i className='fa fa-long-arrow-up'></i> : <></>
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  renderTasks(caseId) {

    const tasksToRender = this.state.tasks.filter(item => item.caseId === caseId);

    return (
      <div>
        {tasksToRender.length > 0 && tasksToRender.map((item, index) => (
          <div key={index}>
            <span>{item.taskName}</span>
          </div>
        ))}
      </div>
    );
  }

  calculateAge(status, dateOpened, dateClosed) {
    if (status === 2)
      return Math.floor((new Date() - new Date(dateOpened)) / this.state.oneDayInMilliseconds);
    else {
      let age = Math.floor((new Date(dateClosed) - new Date(dateOpened)) / this.state.oneDayInMilliseconds);
      if (age < 1)
        age = 1;
      return age;
    }
  }


  zeroPad = (num, places) => String(num).padStart(places, '0');

  renderDataTable(data) {
    data = data.filter(item => {
      const level = this.state.levels.find(level => level.id === parseInt(item.levelId));
      const status = this.state.statuses.find(status => status.id === item.statusId);
      const outcome = this.state.outcomes.find(outcome => outcome.id === parseInt(item.outcomeId));

      item.levelName = level?.name;
      item.levelColor = level?.color;
      item.outcomeName = outcome?.name;
      item.statusName = status?.name;
      item.statusColor = status?.color;

      if (parseInt(this.state.filterCase) === 0) {
        return item
      } else {
        return item.statusId === parseInt(this.state.filterCase)
      }
    }).filter(item => item.description.toLowerCase().includes(this.state.search.toLowerCase()) || this.zeroPad(item.caseNumber, 5).includes(this.state.search.toLowerCase()));
    //data = data.filter(item => item.description.toLowerCase().includes(this.state.search.toLowerCase()) || this.zeroPad(item.caseNumber, 5).includes(this.state.search.toLowerCase()));

    if (this.state.AssignFilter > 0)
      data = data.filter(c => c.latestTask && c.latestTask.fileWithId === this.state.AssignFilter);

    if (this.state.LevelFilter > 0)
      data = data.filter(c => c.levelId === this.state.LevelFilter);

    if (this.state.OutcomeFilter > 0)
      data = data.filter(c => c.outcomeId === this.state.OutcomeFilter);


    data = data.filter(c => {
      const dateOpened = moment(c.dateOpened);
      switch (this.state.showFor) {
        case 1:
          return moment().format("DD/MM/yyyy") === dateOpened.format("DD/MM/yyyy");
        case 2:
          return moment(this.state.specificDate).format("DD/MM/yyyy") === dateOpened.format("DD/MM/yyyy");
        case 3:
          return dateOpened > new Date("1990-01-01");
        default:
          const startDate = moment(this.state.filterDateRange[0]);
          const endDate = moment(this.state.filterDateRange[1]).add(1, "days");
          return dateOpened >= startDate && dateOpened <= endDate;
      }
    });

    return (
      <>

        <div className='table-container' >
          <table className='table table-striped table-responsive table-hover table-sm ' style={{ border: "1px solid #201d39" }} >
            <thead className='bg-blue'>
              <tr style={{ padding: 0, margin: 0 }}>
                <th style={{ width: "10%", cursor: "pointer" }} onClick={() => this.sortBy("File")}>{this.renderSortArrow("File")} File #</th>
                <th style={{ width: "20%", cursor: "pointer" }} onClick={() => this.sortBy("Description")}>{this.renderSortArrow("Description")} File Name </th>
                {/*<th style={{ width: "12%", cursor: "pointer" }} onClick={() => this.sortBy("DateOpened")}>{this.renderSortArrow("DateOpened")} Date Opened </th>*/}
                <th className="text-center" style={{ width: "10%", cursor: "pointer" }} onClick={() => this.sortBy("Age")}>{this.renderSortArrow("Age")} Age (Days)</th>
                <th className="text-center" style={{ width: "8%" }} >Status</th>
                <th style={{ width: "10%", cursor: "pointer" }} onClick={() => this.sortBy("AssignedTo")}>{this.renderSortArrow("AssignedTo")} Assigned To</th>
                {/*<th style={{ width: "15%", cursor: "pointer" }} onClick={() => this.sortBy("ExternalAdjudicator")}>{this.renderSortArrow("ExternalAdjudicator")} External Adjudicator</th>*/}
                <th style={{ width: "10%", cursor: "pointer" }} onClick={() => this.sortBy("AssignedDate")}>{this.renderSortArrow("AssignedDate")} Date Due</th>
                <th style={{ width: "25%" }}> Tasks </th>
                {/*<th className="text-center" style={{ width: "10%", }}> Level</th>*/}
                {/*<th className="text-center" style={{ width: "10%", cursor: "pointer" }} onClick={() => this.sortBy("Outcome")} >{this.renderSortArrow("Outcome")} Outcome</th>*/}
                <th style={{ width: "10%", cursor: "pointer" }} className="text-end" >Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.length ? data.sort((a, b) => {
                switch (this.state.orderBy) {
                  case "FileUp":
                    return b.id - a.id;
                  case "DescriptionDown":
                    return a.description.localeCompare(b.description);
                  case "DescriptionUp":
                    return b.description.localeCompare(a.description);
                  case "DateOpenedDown":
                    return new Date(a.dateOpened) - new Date(b.dateOpened);
                  case "DateOpenedUp":
                    return new Date(b.dateOpened) - new Date(a.dateOpened);
                  case "AgeDown":
                    return new Date().getDate() - new Date(a.dateOpened).getDate();
                  case "AgeUp":
                    return new Date(a.dateOpened).getDate() - new Date().getDate();
                  case "StatusDown":
                    return a.status.localeCompare(b.status);
                  case "StatusUp":
                    return b.status.localeCompare(a.status);
                  case "AssignedToDown":
                    return a.latestTask?.fileWithName.localeCompare(b.latestTask?.fileWithName);
                  case "AssignedToUp":
                    return b.latestTask?.fileWithName.localeCompare(a.latestTask?.fileWithName);
                  case "ExternalAdjudicatorDown":
                    return a.externalAdjudicator.localeCompare(b.externalAdjudicator);
                  case "ExternalAdjudicatorUp":
                    return b.externalAdjudicator.localeCompare(a.externalAdjudicator);
                  case "AssignedDateDown":
                    return (a.latestTask ? new Date(a.latestTask.dateDue) : 0) - (b.latestTask ? new Date(b.latestTask?.dateDue) : 0);
                  case "AssignedDateUp":
                    return (b.latestTask ? new Date(b.latestTask.dateDue) : 0) - (a.latestTask ? new Date(a.latestTask?.dateDue) : 0);
                  case "OutcomeDown":
                    return a.outcomeId - b.outcomeId;
                  case "OutcomeUp":
                    return b.outcomeId - a.outcomeId;
                  default:
                    return a.id - b.id;
                }
              }).map((item, index) => {
                return <tr key={index}>
                  <td className="text-start">{this.zeroPad(item.caseNumber, 5)}</td>
                  <td>{parse(item.description.replace(/<p>/g, "").replace(/<\/p>/g, ""))}</td>
                  {/*<td>{this.formatDate(new Date(item.dateOpened))}</td>*/}
                  {/*<td className="text-center">{Math.floor((new Date() - new Date(item.dateOpened)) / this.state.oneDayInMilliseconds)}</td>*/}
                  <td className="text-center">{this.calculateAge(item.statusId, item.dateOpened, item.dateClosed)}</td>
                  <td className="text-center" style={{ background: item.statusColor, color: checkColorBrightness(item.statusColor) }}>{item.statusName}</td>
                  <td>{item.latestTask ? item.latestTask.fileWithName : "N/A"}</td>
                  {/*<td>{item.externalAdjudicator}</td>*/}
                  <td>{item.latestTask ? this.formatDate(new Date(item.latestTask.dateDue)) : "N/A"}</td>
                  <td>{item.latestTask ? item.latestTask.taskName : "N/A"}</td>
                  {/*<td className="text-center" style={{ background: item.levelColor, color: checkColorBrightness(item.levelColor) }}>{item.levelName}</td>*/}
                  {/*<td className="text-center">{item.outcomeName}</td>*/}
                  <td className="text-end">
                    {/*<i className='fa fa-paperclip me-2' onClick={() => this.editCases(item.id)}></i>*/}
                    {/*<i className='fa fa-edit me-2' onClick={() => this.editCases(item.id)}></i>*/}
                    <i className='fa fa-edit me-2' title="Edit/View Case" onClick={() => document.location.href = "/case-file/" + item.id}></i>
                    {/*<i className='fa fa-trash' onClick={() => this.deleteCase(item.id)}></i>*/}
                  </td>
                </tr>
              }) :
                <tr>
                  <td colSpan={9} className="text-center"><b>No Cases Yet</b></td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </>)
  }

  async deleteCase(id) {

    let confirmDelete = await window.confirm("Are you sure you want to delete this case?");

    if (!confirmDelete) {
      try {
        const response = fetch(`api/cases/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("token")}`
          }
        })

        if (response.ok) {
          this.loadCases();
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  toggleDropDown(dropdownName) {
    this.setState({ [dropdownName]: !this.state[dropdownName] })
  }

  toggleMediaDropDown() {
    this.setState({ mediaDropdown: true })
  }

  toggleTaskDropDown() {
    this.setState({ taskDropdown: !this.state.taskDropdown })
  }

  renderSelectedTable(cases) {
    switch (this.state.active) {
      case this.state.typeOfButtons.newCases:
        return this.renderNewCasesTable();
      default:
        return this.renderDataTable(cases);
    }
  }

  toggleCaseModal() {
    this.setState({ caseModal: !this.state.caseModal })
  }

  render() {
    let content = this.state.loading ? <p><em>Loading...</em></p> : this.renderDataTable(this.state.cases)

    //const filterOptions = [{ label: "Date Range", value: 0 }, { label: "Today", value: 1 }, { label: "Specific Date", value: 2 }];
    const filterOptions = [{ label: "All Dates", value: 3 }, { label: "Date Range", value: 0 }, { label: "Today", value: 1 }, { label: "Specific Date", value: 2 }];
    return (
      <div >
        <div className='dms-header d-flex justify-content-center p-3 align-items-center '>
          <h1 style={{ fontSize: "30px", fontWeight: 100 }}>CASE FILES WORKLOAD</h1>
        </div>
        <Card className='my-5'>
          <CardBody>
            <div className="row">
              <div className="col-md-3 mb-3">
                <label>Filter By Case Status</label>
                <Select
                  className='select2-sm'
                  options={this.state.ddStatuses}
                  isSearchable={true}
                  isClearable={false}
                  backspaceRemovesValue={false}
                  isRtl={false}
                  value={this.state.ddStatuses.filter(option => option.value === this.state.filterCase)}
                  onChange={e => this.setState({ filterCase: e.value })}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label>Filter By Assign To </label>
                <Select
                  className={"select2-sm"}
                  options={this.state.users}
                  isSearchable={true}
                  isClearable={false}
                  backspaceRemovesValue={false}
                  isRtl={false}
                  value={this.state.users.filter(option => option.value === this.state.AssignFilter)}
                  onChange={e => this.setState({ AssignFilter: e.value })}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label>Filter By Level </label>
                <Select
                  className={"select2-sm"}
                  options={this.state.ddLevels}
                  isSearchable={true}
                  isClearable={false}
                  backspaceRemovesValue={false}
                  isRtl={false}
                  value={this.state.ddLevels.filter(option => option.value === this.state.LevelFilter)}
                  onChange={e => this.setState({ LevelFilter: e.value })}
                />
              </div>

              <div className="col-md-3 mb-3">
                <label>Filter By Outcomes </label>
                <Select
                  className={"select2-sm"}
                  options={this.state.ddOutcomes}
                  isSearchable={true}
                  isClearable={false}
                  backspaceRemovesValue={false}
                  isRtl={false}
                  value={this.state.ddOutcomes.filter(option => option.value === this.state.OutcomeFilter)}
                  onChange={e => this.setState({ OutcomeFilter: e.value })}
                />
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-4">
                    <label>Show For</label>
                    <Select
                      className='select2-sm'
                      options={filterOptions}
                      isClearable={false}
                      isSearchable={false}
                      value={filterOptions.find(c => c.value === this.state.showFor)}
                      onChange={e => this.setState({ showFor: parseInt(e.value) })}
                    />
                  </div>
                  <div className="col-md-4" hidden={this.state.showFor !== 0}>
                    <label>Select Date Range</label>
                    <DatePicker
                      className={'w-100 form-control form-control-sm lh-base date-picker'}
                      selectsRange={true}
                      placeholderText='Select Dates'
                      startDate={this.state.filterDateRange[0]}
                      endDate={this.state.filterDateRange[1]}
                      dateFormat='yyyy-MM-dd'
                      onChange={(update) => this.setState({ filterDateRange: update })}
                      isClearable={false}
                    />
                  </div>
                  <div className="col-md-4" hidden={this.state.showFor !== 2}>
                    <label>Select Date</label>
                    {/*{console.log(this.state.specificDate)}*/}
                    <DatePicker
                      className={'w-100 form-control form-control-sm lh-base date-picker'}
                      placeholderText='Select Date'
                      dateFormat={"yyyy-MM-dd"}
                      selected={new Date(this.state.specificDate)}
                      onChange={e => this.setState({ specificDate: moment(e).format("yyyy-MM-DD") })}
                      adjustDateOnChange={true}
                      onSelect={e => console.log(e)}
                      isClearable={false}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3 text-end">
                <label>Filter By Keyword</label>
                <Input className="form-control form-control-sm lh-base" placeholder='Search...' onChange={(e) => this.setState({ search: e.target.value })} />
              </div>
            </div>
          </CardBody>
        </Card>
        {content}
        <ThrobbleHelper />
      </div>
    );
  }

  async loadCases() {
    try {
      ThrobbleHelper.ToggleThrobble(true, "Loading Case Load")
      const response = await fetch('api/cases/GetAllForCaseLoad', {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        // let AssignedList = [{ value: 0, label: "- Select -" }, ...data.map(item => {
        //     return { value: item.assignedTo, label: item.assignedToUserName };
        // })];

        this.setState({ cases: data, loading: false });
        await this.loadStatuses();
        await this.loadLevels();
        await this.loadOutcomes();
        await this.loadUsers(data);
        // await this.loadTasks();
        ThrobbleHelper.ToggleThrobble(false);
      }

    } catch (error) {
      console.error(error)
    }

  }

  async loadTasks() {
    try {
      const response = await fetch('/api/progress', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        this.setState({ tasks: data });
      }
    } catch (error) {
      console.error(error)
    }
  }

  async loadStatuses() {
    try {
      const response = await fetch('/api/status', {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        const ddStatuses = [{ value: 0, label: "- All -" }, ...data.map(item => {
          return { value: item.id, label: item.name }
        })]
        this.setState({ statuses: data, ddStatuses });
      }

    } catch (error) {
      console.error(error)
    }
  }

  async loadLevels() {
    try {
      const response = await fetch('/api/levels', {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (response.ok) {
        const data = await response.json();

        const ddLevels = [{ value: 0, label: "- All -" }, ...data.map(item => {
          return { value: item.id, label: item.name }
        })]
        this.setState({ levels: data, ddLevels });
      }

    } catch (error) {
      console.error(error)
    }
  }

  async loadOutcomes() {
    try {
      const response = await fetch('/api/outcomes', {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        const ddOutcomes = [{ value: 0, label: "- All - " }, ...data.map(item => {
          return { value: item.id, label: item.name }
        })]
        this.setState({ outcomes: data, ddOutcomes });
      }

    } catch (error) {
      console.error(error)
    }
  }

  async loadUsers(Cases) {
    try {
      const response = await fetch('/api/userdata', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      })

      if (response.ok) {
        const data = (await response.json()).sort((a, b) => a.firstName.localeCompare(b.firstName));
        const users = [{ value: 0, label: "- All -" }];
        data.map(item => {
          let caseIdx = Cases.findIndex(c => {
            const fileWithId = c.latestTask?.fileWithId;
            return fileWithId && fileWithId === item.id;
          });
          if (caseIdx > -1)
            users.push({ value: item.id, label: item.firstName + " " + item.lastName });
        })
        this.setState({ users });
      }
    } catch (error) {
      console.error(error)
    }
  }
}

CaseLoad.contextType = myContext;

export default CaseLoad;